import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { GomziNutritionDashboard } from '../pages/gomzi-nutrition/dashboard/DashboardWrapper'
import ManageCoupon from '../pages/gomzi-nutrition/reference-coupon/manage-coupon'
import Reference from '../pages/gomzi-nutrition/reference-coupon/reference'
import { TrainerDashboard } from '../pages/gomzi-nutrition-trainer/dashboard/DashboardWrapper'
import TrainerManageCoupon from '../pages/gomzi-nutrition-trainer/reference-coupon/manage-coupon'
import TrainerReference from '../pages/gomzi-nutrition-trainer/reference-coupon/reference'

const TrainerPrivateRoutes = () => {
	return (
		<Routes>
			<Route element={<MasterLayout />}>
				<Route
					path='dashboard'
					element={<TrainerDashboard />}
				/>
				<Route
					path='/trainer-manage-coupon'
					element={<TrainerManageCoupon />}
				/>
				<Route
					path='/trainer-manage-coupon/reference'
					element={<TrainerReference />}
				/>
			</Route>
		</Routes>
	)
}

// const SuspensedView: FC<WithChildren> = ({ children }) => {
// 	const baseColor = getCSSVariableValue('--bs-primary')
// 	TopBarProgress.config({
// 		barColors: {
// 			'0': baseColor,
// 		},
// 		barThickness: 1,
// 		shadowBlur: 5,
// 	})
// 	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export { TrainerPrivateRoutes }
