/* eslint-disable react/jsx-no-target-blank */
import {
	faAddressBook,
	faCalendarCheck,
	faChartSimple,
	faCheck,
	faComment,
	faDollar,
	faFileLines,
	faHome,
	faListUl,
	faPersonWalking,
} from '@fortawesome/free-solid-svg-icons'
import { AsideMenuItem } from '../AsideMenuItem'

export function HRAsideMenuItems() {
	return (
		<>
			<AsideMenuItem
				to='/hr/dashboard'
				Icon={faHome}
				title='Dashboard'
			/>
			<AsideMenuItem
				to='/hr/employee-detail'
				Icon={faListUl}
				title='Employee Detail'
			/>
			<AsideMenuItem
				to='/hr/attendance'
				Icon={faAddressBook}
				title='Attendance Detail'
			/>
			<AsideMenuItem
				to='/hr/employee-salary'
				Icon={faDollar}
				title='Employee Salary'
			/>
			<AsideMenuItem
				to='/hr/hr-chat'
				Icon={faComment}
				title='Chat'
			/>
			<AsideMenuItem
				to='/hr/task-list'
				Icon={faCheck}
				title='Assign task'
			/>
			<AsideMenuItem
				to='/hr/reports'
				Icon={faFileLines}
				title='Reports'
			/>
			{/* <AsideMenuItem
						to='/hr/lead'
						Icon={faUserPlus}
						title='Leads'
						/> */}
			<AsideMenuItem
				to='/hr/sop-list'
				Icon={faChartSimple}
				title='SOP'
			/>
			<AsideMenuItem
				to='/hr/leave'
				Icon={faPersonWalking}
				title='Leave'
			/>
			<AsideMenuItem
				to='/hr/manual-leave'
				Icon={faPersonWalking}
				title='Add Manual leave'
			/>
			<AsideMenuItem
				to='/hr/target'
				Icon={faCalendarCheck}
				title='Target'
			/>
		</>
	)
}
