import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { DashboardEmployee } from '../pages/employee/dashboard/employee-dashboard'
import EmployeeTask from '../pages/employee/task/task'
import EmployeeLead from '../pages/employee/lead/lead'
import EmployeeDocument from '../pages/employee/document/document'
import EmployeeSOP from '../pages/employee/sop/sop'
import EmployeeTarget from '../pages/employee/target/target'
import EmployeeLeaveManager from '../pages/employee/leave-manager/leave-manager'
import EmployeeRatingManager from '../pages/employee/rating-manager/rating-manager'
import EmployeeChatPage from '../pages/employee/chat/ChatPage'

const EmployeePrivateRoutes = () => {
	return (
		<Routes>
			<Route element={<MasterLayout />}>
				<Route
					path='dashboard'
					element={<DashboardEmployee />}
				/>
				<Route
					path='task'
					element={<EmployeeTask />}
				/>
				<Route
					path='lead'
					element={<EmployeeLead />}
				/>
				<Route
					path='document'
					element={<EmployeeDocument />}
				/>
				<Route
					path='sop'
					element={<EmployeeSOP />}
				/>
				<Route
					path='target'
					element={<EmployeeTarget />}
				/>
				<Route
					path='leave-manager'
					element={<EmployeeLeaveManager />}
				/>
				<Route
					path='rating-manager'
					element={<EmployeeRatingManager />}
				/>

				{/* Page Not Found */}
				<Route
					path='*'
					element={<Navigate to='/error/404' />}
				/>
			</Route>
			
			<Route
				path='employee-chat'
				element={<EmployeeChatPage />}
			/>
		</Routes>
	)
}

export { EmployeePrivateRoutes }
