/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import InputField from '../../../components/InputField'
import { CreateTrainer } from '../../../Functions/FGGroup/Trainer'

const GomziNutritionAddTrainer = () => {
	const navigate = useNavigate()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [employeeData, setEmployeeData] = useState({
		name: '',
		city: '',
		email: '',
		mobile: '',
		company: '',
		password: '',
	})

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = event.target

		setEmployeeData((prevData) => ({
			...prevData,
			[name]: value,
		}))
	}

	const handleAddButtonClick = async () => {
		try {
			setIsSubmitting(true)

			const payload = {
				name: employeeData.name,
				city: employeeData.city,
				email: employeeData.email,
				mobile: employeeData.mobile,
				company: employeeData.company,
				password: employeeData.password,
			}

			await CreateTrainer(payload)

			toast.success('Trainer added successfully')
			setIsSubmitting(false)

			navigate('/nutrition/trainer-list')
		} catch (error: any) {
			toast.error(error.message)
			setIsSubmitting(false)
			console.error(error)
		}
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>Add Trainer</PageTitle>
			<div className='row'>
				<div className='col-12 mt-3'>
					<div className='card py-10'>
						<div className='card-body'>
							<div className='row'>
								<div className='col-md-12'>
									<div className='row'>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Name'
												type='text'
												className='col-12 fv-row mb-7'
												name='name'
												label='Name'
												htmlFor='name'
												value={employeeData.name}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Mobile No.'
												type='number'
												className='col-12 fv-row mb-7'
												name='mobile'
												label='Mobile No.'
												htmlFor='mobile'
												value={employeeData.mobile}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='City'
												type='text'
												className='col-12 fv-row mb-7'
												name='city'
												label='City'
												htmlFor='city'
												value={employeeData.city}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Email'
												type='text'
												className='col-12 fv-row mb-7'
												name='email'
												label='Email'
												htmlFor='email'
												value={employeeData.email}
												onChange={handleInputChange}
											/>
										</div>
										<div className='d-flex col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Password'
												type={showPassword ? 'text' : 'password'}
												className='col-12 fv-row mb-7'
												name='password'
												label='Password'
												htmlFor='password'
												value={employeeData.password}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Company/Gym'
												type='text'
												className='col-12 fv-row mb-7'
												name='company'
												label='Company/Gym'
												htmlFor='company'
												value={employeeData.company}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-12 fv-row mt-4 mb-2 d-flex justify-content-end'>
											<button
												type='button'
												className='btn btn-success mx-2 mb-4'
												onClick={handleAddButtonClick}
												disabled={isSubmitting}>
												{isSubmitting ? 'Submitting...' : 'Submit'}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export { GomziNutritionAddTrainer }
