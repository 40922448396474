import { FC, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { App } from '../App'
import { AuthPage, Logout } from '../modules/auth'
import * as OAuthCallbacks from '../modules/oAuthCallbacks'
import { EmployeeAuthPage } from '../pages/employee/components/AuthPage'
import { PortalAuthPage } from '../pages/fwg/auth/AuthPage'
import { VesuMasterAuthPage } from '../pages/fwg/vesu-auth/AuthPage'
import { TrainerAuthPage } from '../pages/gomzi-nutrition-trainer/auth/AuthPage'
import { NutritionAuthPage } from '../pages/gomzi-nutrition/auth/AuthPage'
import { MasterAuthPage } from '../pages/master/auth/AuthPage'
import { EmployeePrivateRoutes } from './EmployeePrivateRoutes'
import { FgiitPrivateRoutes } from './FgiitPrivateRoutes'
import { FWGPrivateRoutes } from './FWGPrivateRoutes'
import { GCSPrivateRoutes } from './GCSPrivateRoutes'
import { GomziNutritionPrivateRoutes } from './GomziNutritionPrivateRoutes'
import { HRPrivateRoutes } from './HRPrivateRoutes'
import { MasterPrivateRoutes } from './MasterPrivateRoutes'
import { TrainerPrivateRoutes } from './TrainerPrivateRoutes'
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
	const [fwgToken, setFwgToken] = useState<string | null>(null)
	const [fgiitToken, setFgiitToken] = useState<string | null>(null)
	const [hrToken, setHrToken] = useState<string | null>(null)
	const [gcsToken, setGcsToken] = useState<string | null>(null)
	const [masterToken, setMasterToken] = useState<string | null>(null)
	const [vesuMasterToken, setVesuMasterToken] = useState<string | null>(null)
	const [gomziNutritionToken, setGomziNutritionToken] = useState<string | null>(null)
	const [employeeToken, setEmployeeToken] = useState<string | null>(null)
	const [adminType, setAdminType] = useState<string | null>(null)
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

	useEffect(() => {
		const fwg_Token = localStorage.getItem('auth_fwg')
		const fgiit_Token = localStorage.getItem('auth_fg_group')
		const gcs_Token = localStorage.getItem('auth_gcs')
		const master_Token = localStorage.getItem('auth_fg_group')
		const Gomzi_Nutrition_Token = localStorage.getItem('auth_fg_group')
		const employee_Token = localStorage.getItem('auth_fg_group')
		const hr_Token = localStorage.getItem('auth_fg_group')
		const admin_Type = localStorage.getItem('admin')
		const admin_Type_Trainer = localStorage.getItem('admin_type')

		setFwgToken(fwg_Token)
		setFgiitToken(fgiit_Token)
		setGcsToken(gcs_Token)
		setMasterToken(master_Token)
		setVesuMasterToken(master_Token)
		setGomziNutritionToken(Gomzi_Nutrition_Token)
		setEmployeeToken(employee_Token)
		setAdminType(admin_Type)
		setHrToken(hr_Token)

		// Check if the auth_fg_group token is available
		if (fgiit_Token) {
			setIsAuthenticated(true)
		}
	}, [])

	const getDefaultRoute = () => {
		if (adminType === 'Vesu-Master' && vesuMasterToken) return '/fwg/dashboard'
		if (adminType === 'Store' && gomziNutritionToken) return '/nutrition/dashboard'
		if (adminType === 'Gomzi_Nutrition' && gomziNutritionToken) return '/nutrition/dashboard'
		if (adminType === 'Master' && masterToken) return '/master/dashboard'
		if (adminType === 'FGIIT' && fgiitToken) return '/fgiit/dashboard'
		if (adminType === 'FWG' && fwgToken) return '/fwg/dashboard'
		if (adminType === 'HR' && hrToken) return '/hr/dashboard'
		if (adminType === 'GCS' && gcsToken) return '/gcs/dashboard'
		if (adminType === 'Employee' && employeeToken) return '/employee/dashboard'
		if (gomziNutritionToken) return '/nutrition/dashboard'
		return '/error/404'
	}

	useEffect(() => {
		redirectDashboard()
		redirectLoginToUrl()
	}, [])

	const redirectLoginToUrl = () => {
		const adminType = localStorage.getItem('admin_type')
		const admin: any = localStorage.getItem('admin')

		// if (admin) {
		// 	localStorage.removeItem('admin')
		// }
		
		let currentUrl = window.location.pathname
		
		const isLoginPage = currentUrl.includes('login')
		
		if (!admin && !isLoginPage) {
			localStorage.setItem('searchedURL', currentUrl)
		}
		
	}
	
	const redirectDashboard = () => {
		const adminType = localStorage.getItem('admin_type')
		const admin = localStorage.getItem('admin')
		
		let currentUrl = window.location.pathname
		
		let extractedText = currentUrl.split('/')[1]
		
		const isLoginPage = currentUrl.includes('login')
		const masterAdminLogin = localStorage.getItem('fg_master')
		
		const searchedURL: any = localStorage.getItem('searchedURL')
		if (admin && searchedURL) {
			window.location.href = searchedURL
			localStorage.removeItem('searchedURL')
		}

		if (admin && !isLoginPage) {

			if (
				extractedText === 'fgiit' &&
				admin !== 'FGIIT' &&
				admin !== 'Employee' &&
				admin !== 'Store'
			) {
				localStorage.setItem('admin', 'FGIIT')
				window.location.href = currentUrl
			}
			if (extractedText === 'fwg' && admin !== 'FWG' && admin !== 'Employee' && admin !== 'Store') {
				localStorage.setItem('admin', 'FWG')
				window.location.href = currentUrl
			}
			if (
				extractedText === 'master' &&
				admin !== 'Master' &&
				admin !== 'Employee' &&
				admin !== 'Store' &&
				masterAdminLogin === 'main_master'
			) {
				localStorage.setItem('admin', 'Master')
				window.location.href = currentUrl
			} else if (extractedText === 'master' && admin === 'Employee') {
				window.location.href = '/employee/dashboard'
			} else if(extractedText === 'master' && masterAdminLogin !== 'main_master') {
				window.location.href = '/fgiit/dashboard'
			}
			if (
				extractedText === 'nutrition' &&
				admin !== 'Gomzi_Nutrition' &&
				admin !== 'Employee' &&
				admin !== 'Store'
			) {
				localStorage.setItem('admin', 'Gomzi_Nutrition')
				window.location.href = currentUrl
			} else if (extractedText === 'nutrition' && admin === 'Employee') {
				window.location.href = '/employee/dashboard'
			}
			if (extractedText === 'hr' && admin !== 'HR' && admin !== 'Employee' && admin !== 'Store') {
				localStorage.setItem('admin', 'HR')
				window.location.href = currentUrl
			} else if (extractedText === 'hr' && admin === 'Employee') {
				window.location.href = '/employee/dashboard'
			}
			if (extractedText === 'gcs' && admin !== 'GCS' && admin !== 'Employee' && admin !== 'Store') {
				localStorage.setItem('admin', 'GCS')
				window.location.href = currentUrl
			}
			// if (extractedText === 'fwg' && admin !== 'Vesu-Master') {
			// 	localStorage.setItem('admin', 'Vesu-Master')
			// 	window.location.href = currentUrl
			// }
			// if (extractedText === 'employee' && admin !== 'Employee') {
			// 	localStorage.setItem('admin', 'Employee')
			// 	window.location.href = currentUrl
			// }

			if (currentUrl == '/master/login' && adminType) {
				return (window.location.href = '/master/dashboard')
			}
			if (currentUrl == '/nutrition/login' && adminType && admin == 'Store') {
				return (window.location.href = '/nutrition/dashboard')
			}
			if (currentUrl == '/nutrition/login' && adminType && admin !== 'Store') {
				return (window.location.href = '/fgiit/dashboard')
			}
		}
	}

	return (
		<BrowserRouter basename={PUBLIC_URL}>
			<Routes>
				<Route element={<App />}>
					<Route
						path='auth/facebook/callback'
						element={<OAuthCallbacks.FacebookOAuthCallback />}
					/>
					<Route
						path='logout'
						element={<Logout />}
					/>
					{isAuthenticated ? (
						<>
							{fgiitToken && adminType === 'FGIIT' ? (
								<>
									<Route
										path='/fgiit/*'
										element={<FgiitPrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/fgiit/dashboard' />}
									/>
								</>
							) : null}
							{gomziNutritionToken && adminType === 'Store' ? (
								<>
									<Route
										path='/nutrition/*'
										element={<GomziNutritionPrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/nutrition/dashboard' />}
									/>
								</>
							) : null}
							{vesuMasterToken && adminType === 'Vesu-Master' ? (
								<>
									<Route
										path='/fwg/*'
										element={<FWGPrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/fwg/dashboard' />}
									/>
								</>
							) : null}
							{masterToken && adminType === 'Master' ? (
								<>
									<Route
										path='/master/*'
										element={<MasterPrivateRoutes />}
									/>
									<Route
										path='/fgiit/*'
										element={<FgiitPrivateRoutes />}
									/>
									<Route
										path='/fwg/*'
										element={<FWGPrivateRoutes />}
									/>
									<Route
										path='/hr/*'
										element={<HRPrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/master/dashboard' />}
									/>
								</>
							) : null}
							{gomziNutritionToken && adminType === 'Trainer' ? (
								<>
									<Route
										path='/nutrition-trainer/*'
										element={<TrainerPrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/nutrition-trainer/dashboard' />}
									/>
								</>
							) : null}
							{employeeToken && adminType === 'Employee' && (
								<>
									<Route
										path='/employee/*'
										element={<EmployeePrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/employee/dashboard' />}
									/>
								</>
							)}
							{fwgToken ? (
								<>
									<Route
										path='/fwg/*'
										element={<FWGPrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/fwg/dashboard' />}
									/>
								</>
							) : null}
							{hrToken ? (
								<>
									<Route
										path='/hr/*'
										element={<HRPrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/hr/dashboard' />}
									/>
								</>
							) : null}
							{gcsToken ? (
								<>
									<Route
										path='/gcs/*'
										element={<GCSPrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/gcs/dashboard' />}
									/>
								</>
							) : null}
							{gomziNutritionToken && (
								<>
									<Route
										path='/nutrition/*'
										element={<GomziNutritionPrivateRoutes />}
									/>
									<Route
										index
										element={<Navigate to='/nutrition/dashboard' />}
									/>
								</>
							)}
							{fwgToken ||
							fgiitToken ||
							hrToken ||
							gcsToken ||
							employeeToken ||
							gomziNutritionToken ? (
								<Route
									path='/master/*'
									element={<MasterPrivateRoutes />}
								/>
							) : null}
							<Route
								path='*'
								element={<Navigate to={getDefaultRoute()} />}
							/>
						</>
					) : (
						<>
							<Route
								path='login/*'
								element={<AuthPage />}
							/>
							<Route
								path='*'
								element={<Navigate to='/login' />}
							/>
							<Route
								path='employee/login/*'
								element={<EmployeeAuthPage />}
							/>
							<Route
								path='*'
								element={<Navigate to='/employee/login' />}
							/>
							<Route
								path='master/login/*'
								element={<MasterAuthPage />}
							/>

							<Route
								path='*'
								element={<Navigate to='/master/login' />}
							/>
							<Route
								path='fwg-master/login/*'
								element={<VesuMasterAuthPage />}
							/>
							<Route
								path='*'
								element={<Navigate to='/fwg-master/login' />}
							/>
							<Route
								path='portal/login/*'
								element={<PortalAuthPage />}
							/>
							<Route
								path='*'
								element={<Navigate to='/portal/login' />}
							/>
							<Route
								path='trainer/login/*'
								element={<TrainerAuthPage />}
							/>
							<Route
								path='*'
								element={<Navigate to='/trainer/login' />}
							/>
							<Route
								path='nutrition/login/*'
								element={<NutritionAuthPage />}
							/>
							<Route
								path='*'
								element={<Navigate to='/nutrition/login' />}
							/>
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export { AppRoutes }
