import { useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { GetExercisePlan, GetExpenses, GetInvoice, GetInvoiceStats } from '../../../Functions/FWG'
import { FWGListsWidget1 } from './FWGListsWidget1'
import { FWGListsWidget4 } from './FWGListsWidget4'
import { FWGMixedWidget8 } from './FWGMixedWidget8'

const DashboardWrapper = () => {
	const [invoiceData, setInvoiceData] = useState<any>([])
	const [expenseData, setExpenseData] = useState<any>([])
	const [exercisePlan, setExercisePlan] = useState<number>(0)
	const [fwgSubAdminShow, setFwgSubAdminShow] = useState('')

	const fetchInvoiceData = async () => {
		try {
			const response: any = await GetInvoiceStats()
			setInvoiceData(response.data)
		} catch (error: any) {
			console.error(error)
		}
	}

	const fetchExpenseData = async () => {
		try {
			const response: any = await GetExpenses()
			setExpenseData(response.data)
		} catch (error: any) {
			console.error(error)
		}
	}

	const fetchExercisePlan = async () => {
		try {
			const response: any = await GetExercisePlan()
			setExercisePlan(response.data.length)
		} catch (error: any) {
			console.error(error)
		}
	}

	// Calculate sums
	const totalAmount = invoiceData.reduce(
		(sum: any, invoice: any) => sum + parseFloat(invoice.total_net_amount),
		0
	)

	const totalPaidAmount = invoiceData.reduce(
		(sum: any, invoice: any) => sum + parseFloat(invoice.total_paid_amount),
		0
	)

	const totalExpenseAmount = invoiceData.reduce(
		(sum: number, invoice: any) => sum + parseFloat(invoice.total_due_amount),
		0
	)

	// Format the total amounts with commas
	const formattedTotalAmount = { totalAmount, totalPaidAmount, totalExpenseAmount }

	useEffect(() => {
		const portalAdminLogin = localStorage.getItem('fwg_portal')
		if (portalAdminLogin === 'fwg_portal') {
			setFwgSubAdminShow(portalAdminLogin)
		}
		fetchInvoiceData()
		fetchExpenseData()
		fetchExercisePlan()
	}, [])

	return (
		<>
			<PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
			<div className='row g-5 g-xl-5'>
				<div className='col-lg-4'>
					<FWGListsWidget1
						formattedTotalAmount={formattedTotalAmount}
						exercisePlan={exercisePlan}
					/>
				</div>

				<div className='col-md-4'>
					<FWGMixedWidget8
						className='card'
						chartColor='primary'
						chartHeight='150px'
					/>
				</div>
				{fwgSubAdminShow === 'fwg_portal' ? (
					<></>
				) : (
					<div className='col-md-4'>
						<FWGListsWidget4
							className='card'
							chartColor='info'
							chartHeight='150px'
						/>
					</div>
				)}
			</div>
		</>
	)
}

export { DashboardWrapper }
