/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import InputField from '../../../components/InputField'
import SelectField from '../../../components/SelectField'
import { FileUploadToFGGroup } from '../../../Functions/FGGroup'
import { CreateEmployee, GetNextEmployeeCode } from '../../../Functions/FGGroup/Employee'

const HREmployeeAdd = () => {
	const navigate = useNavigate()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [employee_code, setEmployee_code] = useState()
	const [employeeData, setEmployeeData] = useState({
		employee_code: '',
		name: '',
		position: '',
		salary: '',
		bank_name: '',
		account_details: '',
		IFSC: '',
		email: '',
		mobile: '',
		address: '',
		photo: '',
		proof: '',
		document: '',
		password: '',
		editImage: false,
		selectedFile: '',
	})

	const fetchEmployeeData = async (page?: number) => {
		try {
			const response: any = await GetNextEmployeeCode()
			setEmployee_code(response.data.employee_code)
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		fetchEmployeeData()
	}, [])

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = event.target

		setEmployeeData((prevData) => ({
			...prevData,
			[name]: value,
		}))
	}

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, files } = event.target as HTMLInputElement
		if (files && files[0]) {
			setEmployeeData((prevData) => ({
				...prevData,
				[name]: files[0],
			}))
		}
	}

	const handleAddButtonClick = async () => {
		try {
			setIsSubmitting(true)
			let imgUrl = ''
			let proofUrl = ''
			let docUrl = ''

			if (employeeData.photo) {
				const imageUrl: any = await FileUploadToFGGroup([employeeData.photo], {
					directory: 'files',
				})
				imgUrl = imageUrl.data?.fileURLs[0]
				toast.success('Image uploaded successfully')
			}

			if (employeeData.document) {
				const proofDataUrl: any = await FileUploadToFGGroup([employeeData.proof], {
					directory: 'files',
				})
				proofUrl = proofDataUrl.data?.fileURLs[0]
				toast.success('Proof uploaded successfully')
			}

			if (employeeData.document) {
				const documentUrl: any = await FileUploadToFGGroup([employeeData.document], {
					directory: 'files',
				})
				docUrl = documentUrl.data?.fileURLs[0]
				toast.success('Document uploaded successfully')
			}

			const payload: any = {
				employee_code: employeeData?.employee_code ? employeeData?.employee_code : employee_code,
				name: employeeData.name,
				position: employeeData.position,
				salary: Number(employeeData.salary),
				account_details: {
					bank_name: employeeData.bank_name,
					account_number: employeeData.account_details,
					IFSC: employeeData.IFSC,
				},
				email: employeeData.email,
				mobile: employeeData.mobile,
				address: employeeData.address,
				photo: imgUrl,
				proof: proofUrl,
				documents: docUrl,
				password: employeeData.password,
			}

			await CreateEmployee(payload)

			toast.success('Employee added successfully')
			setIsSubmitting(false)

			navigate('/hr/employee-detail')
		} catch (error: any) {
			toast.error(error.message)
			setIsSubmitting(false)
			console.error(error)
		}
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>Add Employee</PageTitle>
			<div className='row'>
				<div className='col-12 mt-3'>
					<div className='card py-10'>
						<div className='card-body'>
							<div className='row'>
								<div className='col-md-12'>
									<div className='row'>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Employee Code'
												type='text'
												className='col-12 fv-row mb-7'
												name='employee_code'
												label='Employee Code'
												htmlFor='employee_code'
												value={employeeData.employee_code ? employeeData.employee_code : employee_code}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Name'
												type='text'
												className='col-12 fv-row mb-7'
												name='name'
												label='Name'
												htmlFor='name'
												value={employeeData.name}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<SelectField
												className='col-12 fv-row'
												name='position'
												label='Position'
												htmlFor='position'
												value={employeeData.position}
												onChange={handleInputChange}
												options={[
													'Developer',
													'Marketing',
													'Franchise Owner',
													'Graphic Designer',
													'Sales Manager',
													'Video Editor',
													'Back Office',
													'Trainer',
													'Dietician',
													'Telecalling',
													'Branch Manager',
													'Human Resources',
													'Team Leader',
													'Manager',
													'Director',
													'Social Media Assistant',
												]}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Salary'
												type='text'
												className='col-12 fv-row mb-7'
												name='salary'
												label='Salary'
												htmlFor='salary'
												value={employeeData.salary}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Mobile No.'
												type='number'
												className='col-12 fv-row mb-7'
												name='mobile'
												label='Mobile No.'
												htmlFor='mobile'
												value={employeeData.mobile}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Address'
												type='text'
												className='col-12 fv-row mb-7'
												name='address'
												label='Address'
												htmlFor='address'
												value={employeeData.address}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-6 fv-row'>
											<InputField
												placeholder='Email'
												type='text'
												className='col-12 fv-row'
												name='email'
												label='Email'
												htmlFor='email'
												value={employeeData.email}
												onChange={handleInputChange}
											/>
										</div>
										<div className='d-flex col-md-6 fv-row'>
											<InputField
												placeholder='Password'
												type={showPassword ? 'text' : 'password'}
												className='col-12 fv-row'
												name='password'
												label='Password'
												htmlFor='password'
												value={employeeData.password}
												onChange={handleInputChange}
											/>
										</div>
										<div className='col-md-12 mt-3'>
											<h2 className='mb-5'>Account Details:</h2>
											<div className='row'>
												<div className='col-md-4 fv-row mb-7'>
													<InputField
														placeholder='Bank Name'
														type='text'
														className='col-12 fv-row mb-7'
														name='bank_name'
														label='Bank Name'
														htmlFor='bank_name'
														value={employeeData.bank_name}
														onChange={handleInputChange}
													/>
												</div>
												<div className='col-md-4 fv-row mb-7'>
													<InputField
														placeholder='Account No.'
														type='text'
														className='col-12 fv-row mb-7'
														name='account_details'
														label='Account No.'
														htmlFor='account_details'
														value={employeeData.account_details}
														onChange={handleInputChange}
													/>
												</div>
												<div className='col-md-4 fv-row mb-7'>
													<InputField
														placeholder='IFSC Code'
														type='text'
														className='col-12 fv-row mb-7'
														name='IFSC'
														label='IFSC Code'
														htmlFor='IFSC'
														value={employeeData.IFSC}
														onChange={handleInputChange}
													/>
												</div>
											</div>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Upload Photo'
												type='file'
												className='col-12 fv-row mb-7'
												name='photo'
												label='Upload Photo'
												htmlFor='photo'
												onChange={handleFileChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Upload Proof'
												type='file'
												className='col-12 fv-row mb-7'
												name='proof'
												label='Upload Proof'
												htmlFor='proof'
												onChange={handleFileChange}
											/>
										</div>
										<div className='col-md-4 fv-row mb-7'>
											<InputField
												placeholder='Upload Pdf'
												type='file'
												className='col-12 fv-row mb-7'
												name='document'
												label='Upload Pdf'
												htmlFor='document'
												onChange={handleFileChange}
											/>
										</div>

										<div className='col-md-12 fv-row mt-4 mb-2 d-flex justify-content-end'>
											<button
												type='button'
												className='btn btn-success mx-2 mb-4'
												onClick={handleAddButtonClick}
												disabled={isSubmitting}>
												{isSubmitting ? 'Submitting...' : 'Submit'}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export { HREmployeeAdd }
