/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { MultiSelect } from 'react-multi-select-component'
import { PageTitle } from '../../../../_metronic/layout/core'
import InputField from '../../../components/InputField'
import { CreateJobPlacement, GetFitnessCourse } from '../../../Functions/FGGroup'
import { jobPlacementSchema } from '../../../modules/validation/validation'
import TableButton from '../../../components/TableButton'
import { useNavigate } from 'react-router-dom'

const JobPlacementAdd = () => {
	const [jobData, setJobData] = useState({
		title: '',
		contact_person: '',
		website: '',
		contact_email: '',
		contact_mobile: '',
		company_name: '',
		vacancies: '',
		start_date: '',
		banner_image: '',
		end_date: '',
		related_course: [],
	})
	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false)

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { id, name, value } = event.target

		if (id === 'fileInput' && event.target instanceof HTMLInputElement && event.target.files) {
			const file = event.target.files[0]

			setJobData((prevData) => ({
				...prevData,
				selectedFile: file,
				cover_image: URL.createObjectURL(file),
				editImage: true,
			}))
		} else {
			setJobData((prevData) => ({
				...prevData,
				[name]: value,
			}))
		}
	}

	const handleAddButtonClick = async () => {
		// const { error } = jobPlacementSchema.validate(jobData, { abortEarly: false })

		// if (error) {
		// 	error.details.forEach((detail) => toast.error(detail.message))
		// 	return
		// }
		try {
			setIsSubmitting(true)

			const course = jobData.related_course.map((el: any) => {
				return el.id
			})

			const payload: any = {
				title: jobData.title,
				contact_name: jobData.contact_person,
				website: jobData.website,
				email: jobData.contact_email,
				mobile: jobData.contact_mobile,
				company_name: jobData.company_name,
				vacancies: parseInt(jobData.vacancies),
				start_date: jobData.start_date,
				end_date: jobData.end_date,
				banner_image: jobData.banner_image,
				related_course: course,
			}

			await CreateJobPlacement(payload)

			navigate('/fgiit/job-placement');

			toast.success('Placement Created Successfully')
			setIsSubmitting(false)
		} catch (error: any) {
			toast.error(error.message)
			setIsSubmitting(false)
			console.error(error)
		}
	}

	const handleMultiSelectChange = (selected: any) => {
		setJobData((prevData) => ({
			...prevData,
			related_course: selected,
		}))
	}

	const [courseData, setCourseData] = useState<any>([])

	const fetchUserData = async () => {
		try {
			const response = await GetFitnessCourse()
			let filteredData: any = response.data
			filteredData = filteredData.map((course: any) => {
				return { value: course.course_name, label: `${course?.course_name} - ${course?.course_category}`, id: course._id }
			})
			setCourseData(filteredData)
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		fetchUserData()
	}, [])

	return (
		<>
			<PageTitle breadcrumbs={[]}>Job & Placement View</PageTitle>
			<>
				<div className='row'>
					<div className='col-12 mt-3'>
						<div className='card py-10'>
							<div className='card-body'>
								<div className='row justify-content-end'>
									<div className='col-md-12 '>
										<div className='row'>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Placement Title'
													type='text'
													className='col-12 fv-row mb-7'
													name='title'
													label='Placement Title'
													htmlFor='title'
													value={jobData.title}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Contact Person name'
													type='text'
													className='col-12 fv-row mb-7'
													name='contact_person'
													label='Contact Person name'
													htmlFor='contact_person'
													value={jobData.contact_person}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Website'
													type='text'
													className='col-12 fv-row mb-7'
													name='website'
													label='Website'
													htmlFor='website'
													value={jobData.website}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Email'
													type='text'
													className='col-12 fv-row mb-7'
													name='contact_email'
													label='Email'
													htmlFor='contact_email'
													value={jobData.contact_email}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Mobile'
													type='text'
													className='col-12 fv-row mb-7'
													name='contact_mobile'
													label='Mobile'
													htmlFor='contact_mobile'
													value={jobData.contact_mobile}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Company Name'
													type='text'
													className='col-12 fv-row mb-7'
													name='company_name'
													label='Company Name'
													htmlFor='company_name'
													value={jobData.company_name}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Banner Image'
													type='text'
													className='col-12 fv-row mb-7'
													name='banner_image'
													label='Banner Image'
													htmlFor='banner_image'
													value={jobData.banner_image}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Vacancies'
													type='number'
													className='col-12 fv-row mb-7'
													name='vacancies'
													label='Vacancies'
													htmlFor='vacancies'
													value={`${jobData.vacancies}`}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='Start Date'
													type='date'
													className='col-12 fv-row mb-7'
													name='start_date'
													label='Start Date'
													htmlFor='start_date'
													value={jobData.start_date}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<InputField
													placeholder='End Date'
													type='date'
													className='col-12 fv-row mb-7'
													name='end_date'
													label='End Date'
													htmlFor='end_date'
													value={jobData.end_date}
													onChange={handleInputChange}
												/>
											</div>
											<div className='col-md-6 fv-row mb-2'>
												<label
													className='required fw-bold fs-6 mb-2'
													htmlFor='Select'>
													Courses
												</label>
												<MultiSelect
													options={courseData}
													value={jobData.related_course}
													onChange={handleMultiSelectChange}
													labelledBy='Select'
													className='fv-row'
												/>
											</div>

										</div>
									</div>
									<div className='col-2 fv-row mt-4 mb-2 d-flex justify-content-end'>
										<TableButton
											action="add"
											onClick={handleAddButtonClick}
											text={isSubmitting ? 'Please wait, Adding Job...' : 'Add Job'}
											showIcon={false}
											disabled={isSubmitting}
											className={`btn-block mb-4 w-100 ${isSubmitting ? 'disabled' : ''}`}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	)
}
export { JobPlacementAdd }
