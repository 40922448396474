import { PageTitle } from '../../../../_metronic/layout/core'
import { DashboardWrapper } from '../../fwg/dashboard/DashboardWrapper'

const MasterFWGDashboard = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>FWG</PageTitle>
			<DashboardWrapper />
		</>
	)
}

export default MasterFWGDashboard
