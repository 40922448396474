import { getAPIHeaders } from '../../../_metronic/helpers/AuthToken'
import { APIDelete, APIGet, APIPost } from '../../../_metronic/helpers/Utils'
import * as FGGroupEndpoints from '../../constants/fg_group_endpoints'

export function CreateTask(body: {
	task_title: string
	start_date: string
	end_date: string
	remark_by_employee?: string
	remark_by_hr?: string
	file_url: string
	admin_id: string
}): Promise<FGGroupAPIResponse> {
	return APIPost(FGGroupEndpoints.CreateTask, getAPIHeaders('fg_group'), undefined, body)
}

export function UpdateTask(body: {
	task_id: string
	task_title?: string
	start_date?: string
	end_date?: string
	remark_by_hr?: string
	file_url?: string
	admin_id?: string
}): Promise<FGGroupAPIResponse> {
	return APIPost(FGGroupEndpoints.UpdateTask, getAPIHeaders('fg_group'), undefined, body)
}

export function ActionTask(body: {
	task_id?: string
	status?: string
	remark_by_employee?: string
	remark_by_hr?: string
}): Promise<FGGroupAPIResponse> {
	return APIPost(FGGroupEndpoints.ActionTask, getAPIHeaders('fg_group'), undefined, body)
}

export function GetTask(
	query?: { task_id?: string, admin_id?: string } & FGGroupSearchOptions &
		FGGroupPaginationOptions &
		FGGroupSortOptions
): Promise<FGGroupAPIResponse> {
	return APIGet(FGGroupEndpoints.GetTasks, getAPIHeaders('fg_group'), query)
}

export function DeleteTask(query: { task_id: string }): Promise<FGGroupAPIResponse> {
	return APIDelete(FGGroupEndpoints.RemoveTask, getAPIHeaders('fg_group'), query)
}