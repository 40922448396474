/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import { EmployeeAsideMenuItems } from './components/EmployeeAsideMenuItems'
import { FGIITAsideMenuItems } from './components/FGIITAsideMenuItems'
import { FWGAsideMenuItems } from './components/FWGAsideMenuItems'
import { FwgPortalAsideMenuItems } from './components/FwgPortalAsideMenuItems'
import { GCSAsideMenuItems } from './components/GCSAsideMenuItems'
import { GomziNutritionAsideMenuItems } from './components/GomziNutritionAsideMenuItems'
import { HRAsideMenuItems } from './components/HRAsideMenuItems'
import { MasterAsideMenuItems } from './components/MasterAsideMenuItems'
import { StoreNutritionAsideMenuItems } from './components/StoreNutritionAsideMenuItems'
import { TrainerAsideMenuItems } from './components/TrainerAsideMenuItems'
import { VesuFWGAsideMenuItems } from './components/VesuFWGAsideMenuItems'

export function AsideMenuMain() {
	const [portalAdminType, setPortalAdminType] = useState('')
	const [adminType, setAdminType] = useState('')
	const location = useLocation()

	useEffect(() => {
		const portalAdminType = localStorage.getItem('fwg_portal')
		const storedAdminType = localStorage.getItem('admin')
		if (portalAdminType) {
			setPortalAdminType(portalAdminType)
		}
		if (storedAdminType) {
			setAdminType(storedAdminType)
		}
	}, [])

	useEffect(() => {
		const pathSegments = location.pathname.split('/')
		const firstSegment = pathSegments[1]
	}, [location])

	return (
		<>
			{adminType === 'Trainer' && <TrainerAsideMenuItems />}
			{portalAdminType === 'fwg_portal' && <FwgPortalAsideMenuItems />}
			{adminType === 'FGIIT' && <FGIITAsideMenuItems />}
			{adminType === 'FWG' && portalAdminType !== 'fwg_portal' && <FWGAsideMenuItems />}
			{adminType === 'Vesu-Master' && <VesuFWGAsideMenuItems />}
			{adminType === 'HR' && <HRAsideMenuItems />}
			{adminType === 'GCS' && <GCSAsideMenuItems />}
			{adminType === 'Employee' && <EmployeeAsideMenuItems />}
			{adminType === 'Master' && <MasterAsideMenuItems />}
			{adminType === 'Gomzi_Nutrition' && <GomziNutritionAsideMenuItems />}
			{adminType === 'Store' && <StoreNutritionAsideMenuItems />}
		</>
	)
}
