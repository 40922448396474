import React from 'react'
import { MultiSelect, Option } from 'react-multi-select-component'

type Props = {
	className: string
	label: string
	name: string
	value: string | string[]
	htmlFor: string
	onChange: (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void
	options: string[]
	showLabel?: boolean
	multiSelect?: boolean
	marginRemove?: boolean
	disabled?: boolean | undefined
}

const SelectField: React.FC<Props> = ({
	className,
	label,
	value,
	name,
	onChange,
	htmlFor,
	options,
	disabled,
	showLabel = true,
	multiSelect = false,
	marginRemove,
}) => {
	const formattedOptions = options.map((option) => ({ label: option, value: option }))

	const selectedValues = typeof value === 'string' ? value.split(',') : []
	const selectedOptions = formattedOptions.filter((option) => selectedValues.includes(option.value))

	const handleMultiSelectChange = (selected: Option[]) => {
		const selectedValues = selected.map((option) => option.value).join(',')
		const syntheticEvent = {
			target: {
				name: name,
				value: selectedValues,
			},
		} as unknown as React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
		onChange(syntheticEvent)
	}

	return (
		<div className={`fv-row ${marginRemove ? `mb-0` : `mb-md-7 mb-3`} ${className}`}>
			{showLabel && (
				<label
					htmlFor={htmlFor}
					className='fw-bold fs-6  mb-md-5 mb-2'>
					{label}
				</label>
			)}
			{multiSelect ? (
				<MultiSelect
					options={formattedOptions}
					value={selectedOptions}
					onChange={handleMultiSelectChange}
					labelledBy={`Select ${label}`}
					className='selectfield-bg mb-3 mb-lg-0'
					disabled={disabled}
				/>
			) : (
				<div className='selectfield-bg mb-3 mb-lg-0'>
					<select
						name={name}
						value={value}
						onChange={onChange}
						disabled={disabled}
						className={`form-select ${disabled ? `inputfield-bg1` : `inputfield-bg`}`}>
						<option value='select'>Select</option>
						{formattedOptions.map((option) => (
							<option
								key={option.value}
								value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
			)}
		</div>
	)
}

export default SelectField

// import React from 'react';

// type Props = {
//     className: string;
//     label: string;
//     name: string;
//     value: string;
//     htmlFor: string;
//     onChange: (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
//     options: string[];
//     showLabel?: boolean; // Optional prop to show or hide the label
// };

// const SelectField: React.FC<Props> = ({
//     className,
//     label,
//     value,
//     name,
//     onChange,
//     htmlFor,
//     options,
//     showLabel = true, // Default value is true
// }) => {
//     return (
//         <div className={`fv-row mb-7 ${className}`}>
//             {showLabel && (
//                 <label htmlFor={htmlFor} className='required fw-bold fs-6 mb-5'>
//                     {label}
//                 </label>
//             )}
//             <select
//                 className='form-control form-control-solid mb-3 mb-lg-0'
//                 autoComplete='off'
//                 name={name}
//                 value={value}
//                 onChange={onChange}
//             >
//                 <option>Select {label}</option>
//                 {options.map((option, index) => (
//                     <option key={index} value={option}>
//                         {option}
//                     </option>
//                 ))}
//             </select>
//         </div>
//     );
// };

// export default SelectField;
