import { PageTitle } from '../../../../_metronic/layout/core'
import { Vertical } from '../../../modules/wizards/components/Vertical'

const SubscriptionAdd = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Add Subscription</PageTitle>
			<Vertical />
		</>
	)
}

export default SubscriptionAdd
