import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { HRAttendance } from '../pages/hr/attendance/attendance'
import AttendanceViewUser from '../pages/hr/attendance/attendance-view'
import HRChatPage from '../pages/hr/chat/ChatPage'
import { DashboardHR } from '../pages/hr/dashboard/dashboard-hr'
import { HREmployeeAdd } from '../pages/hr/employee/employee-add'
import HREmployeeDetail from '../pages/hr/employee/employee-detail'
import { HREmployeeMemo } from '../pages/hr/employee/employee-memo'
import { HREmployeeRatting } from '../pages/hr/employee/employee-rating'
import { HRMemberDetail } from '../pages/hr/employee/employee-view'
import HRLead from '../pages/hr/leads/leads'
import HRLeave from '../pages/hr/leave/leave'
import HRManualLeave from '../pages/hr/manual-leave/manualLeave'
import HRReports from '../pages/hr/reports/reports'
import { HREmployeeSalary } from '../pages/hr/salary/employee-salary'
import HREmployeeSalaryView from '../pages/hr/salary/employee-salary-view'
import HRAssignSopList from '../pages/hr/sop/AssignSopList'
import HRTarget from '../pages/hr/target/target'
import HRAssignTaskList from '../pages/hr/task/AssignTaskList'

const HRPrivateRoutes = () => {
	return (
		<Routes>
			<Route element={<MasterLayout />}>
				<Route
					path='dashboard'
					element={<DashboardHR />}
				/>
				<Route
					path='employee-detail'
					element={<HREmployeeDetail />}
				/>
				<Route
					path='employee-add'
					element={<HREmployeeAdd />}
				/>
				<Route
					path='employee-view'
					element={<HRMemberDetail />}
				/>
				<Route
					path='employee-memo'
					element={<HREmployeeMemo />}
				/>
				<Route
					path='employee-ratting'
					element={<HREmployeeRatting />}
				/>
				<Route
					path='task-list'
					element={<HRAssignTaskList />}
				/>
				<Route
					path='sop-list'
					element={<HRAssignSopList />}
				/>
				<Route
					path='reports'
					element={<HRReports />}
				/>
				<Route
					path='lead'
					element={<HRLead />}
				/>
				<Route
					path='leave'
					element={<HRLeave />}
				/>
				<Route
					path='target'
					element={<HRTarget />}
				/>
				<Route
					path='attendance'
					element={<HRAttendance />}
				/>
				<Route
					path='attendance-view'
					element={<AttendanceViewUser />}
				/>
				<Route
					path='employee-salary'
					element={<HREmployeeSalary />}
				/>
				<Route
					path='employee-salary-view'
					element={<HREmployeeSalaryView />}
				/>

				<Route
					path='manual-leave'
					element={<HRManualLeave />}
				/>

				{/* Page Not Found */}
				<Route
					path='*'
					element={<Navigate to='/error/404' />}
				/>
			</Route>

			<Route
				path='hr-chat'
				element={<HRChatPage />}
			/>
		</Routes>
	)
}

export { HRPrivateRoutes }
