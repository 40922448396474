import { getAPIHeaders } from '../../../_metronic/helpers/AuthToken'
import { APIGet, APIPatch } from '../../../_metronic/helpers/Utils'
import * as FGGroupEndpoints from '../../constants/fg_group_endpoints'

export function GetListingForAdmin(
	query?: {
		listing_id?: string
		page?: number
		limit?: number
		approval_status?: string
	} & FGGroupSearchOptions &
		FGGroupPaginationOptions &
		FGGroupSortOptions
): Promise<FGGroupAPIResponse> {
	return APIGet(FGGroupEndpoints.GetBusinessListing, getAPIHeaders('fg_group'), query)
}

export function ListingAction(body: {
	listing_id: string
	approval_status: string
	comment?: string
}): Promise<FGGroupAPIResponse> {
	return APIPatch(FGGroupEndpoints.ListingAction, getAPIHeaders('fg_group'), undefined, body)
}
