import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import AbandonedList from '../pages/gomzi-nutrition/abandoned-list/abandoned-list'
import AddToCart from '../pages/gomzi-nutrition/add-to-cart/add-to-cart'
import { GomziNutritionDashboard } from '../pages/gomzi-nutrition/dashboard/DashboardWrapper'
import { AddExpenseNutrition } from '../pages/gomzi-nutrition/expense/create-expense'
import ExpenseListDetailsNutrition from '../pages/gomzi-nutrition/expense/expense-details'
import NutritionProductFeedback from '../pages/gomzi-nutrition/feedback/product-feedback'
import { AddGomziNutritionProduct } from '../pages/gomzi-nutrition/gomzi-nutrition/add-gomzi-nutrition-product'
import { EditGomziNutritionProduct } from '../pages/gomzi-nutrition/gomzi-nutrition/edit-gomzi-nutrition-product'
import GomziNutritionProductList from '../pages/gomzi-nutrition/gomzi-nutrition/gomzi-nutrition-product-list'
import NutitionCreateInvoice from '../pages/gomzi-nutrition/invoice/create-invoice'
import NutitionTotalSalesList from '../pages/gomzi-nutrition/invoice/total-sales'
import NutitionUpdateInvoice from '../pages/gomzi-nutrition/invoice/update-invoice'
import NutritionAllOrder from '../pages/gomzi-nutrition/product/all-order/all-order'
import { NutritionOrderView } from '../pages/gomzi-nutrition/product/all-order/order-view'
import ProductOrder from '../pages/gomzi-nutrition/product/product-order'
import { ProductOrderView } from '../pages/gomzi-nutrition/product/product-order-view'
import ManageCoupon from '../pages/gomzi-nutrition/reference-coupon/manage-coupon'
import Reference from '../pages/gomzi-nutrition/reference-coupon/reference'
import GomziNutritionProductStock from '../pages/gomzi-nutrition/stock/gomzi-product-stock-list'
import { GomziNutritionAddTrainer } from '../pages/gomzi-nutrition/trainers/add-trainer'
import GomziNutritionTrainerDetail from '../pages/gomzi-nutrition/trainers/trainer-list'
import GomziNutritionProductVariationList from '../pages/gomzi-nutrition/gomzi-nutrition/product-variation-list'
import { EditGomziNutritionVariationProduct } from '../pages/gomzi-nutrition/gomzi-nutrition/edit-product-variation'
import { AddGomziNutritionVariationProduct } from '../pages/gomzi-nutrition/gomzi-nutrition/add-product-variation'

const GomziNutritionPrivateRoutes = () => {
	return (
		<Routes>
			<Route element={<MasterLayout />}>
				<Route
					path='dashboard'
					element={<GomziNutritionDashboard />}
				/>
				<Route
					path='/gomzi-nutrition-product'
					element={<GomziNutritionProductList />}
				/>
				<Route
					path='/gomzi-nutrition-product-add'
					element={<AddGomziNutritionProduct />}
				/>
				<Route
					path='gomzi-nutrition-product-edit'
					element={<EditGomziNutritionProduct />}
				/>
				<Route
					path='/gomzi-nutrition-product/variation-list'
					element={<GomziNutritionProductVariationList />}
				/>
				<Route
					path='/gomzi-nutrition-product/variation-edit'
					element={<EditGomziNutritionVariationProduct />}
				/>
				<Route
					path='/gomzi-nutrition-product/variation-add'
					element={<AddGomziNutritionVariationProduct />}
				/>
				<Route
					path='/add-to-cart'
					element={<AddToCart />}
				/>
				<Route
					path='/abandoned-list'
					element={<AbandonedList />}
				/>
				<Route
					path='/create-invoice-nutrition'
					element={<NutitionCreateInvoice />}
				/>
				<Route
					path='/update-invoice-nutrition'
					element={<NutitionUpdateInvoice />}
				/>
				<Route
					path='/nutrition-invoice'
					element={<NutitionTotalSalesList />}
				/>
				<Route
					path='product-order'
					element={<ProductOrder />}
				/>
				<Route
					path='product-order-view'
					element={<ProductOrderView />}
				/>
				<Route
					path='/all-order/view-order'
					element={<NutritionOrderView />}
				/>
				<Route
					path='nutrition-product-feedback'
					element={<NutritionProductFeedback />}
				/>

				<Route
					path='/manage-coupon'
					element={<ManageCoupon />}
				/>
				<Route
					path='/manage-coupon/reference'
					element={<Reference />}
				/>

				<Route
					path='expense/create'
					element={<AddExpenseNutrition />}
				/>
				<Route
					path='expense'
					element={<ExpenseListDetailsNutrition />}
				/>
				<Route
					path='expense/update'
					element={<AddExpenseNutrition />}
				/>

				<Route
					path='trainer-list'
					element={<GomziNutritionTrainerDetail />}
				/>
				<Route
					path='add-trainer'
					element={<GomziNutritionAddTrainer />}
				/>
				<Route
					path='product-stock-management'
					element={<GomziNutritionProductStock />}
				/>
			</Route>
		</Routes>
	)
}

// const SuspensedView: FC<WithChildren> = ({ children }) => {
// 	const baseColor = getCSSVariableValue('--bs-primary')
// 	TopBarProgress.config({
// 		barColors: {
// 			'0': baseColor,
// 		},
// 		barThickness: 1,
// 		shadowBlur: 5,
// 	})
// 	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export { GomziNutritionPrivateRoutes }
