/* eslint-disable react/jsx-no-target-blank */
import {
	faBook,
	faBuildingCircleCheck,
	faCalendarDays,
	faCartArrowDown,
	faCartShopping,
	faChartLine,
	faFileInvoice,
	faHome,
	faJar,
	faMapMarkerAlt,
	faUser,
	faUserCog,
	faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { AsideMenuItem } from '../AsideMenuItem'
import { AsideMenuItemWithSub } from '../AsideMenuItemWithSub'

export function FGIITAsideMenuItems() {
	return (
		<>
			<AsideMenuItem
				to='/fgiit/dashboard'
				Icon={faHome}
				title='Dashboard'
			/>
			<AsideMenuItem
				to='student-dashboard'
				Icon={faUser}
				title='Student Dashboard'
			/>
			<AsideMenuItem
				to='admin-user'
				Icon={faUserCog}
				title='Admin User'
			/>
			<AsideMenuItem
				to='franchise/franchise-list'
				Icon={faMapMarkerAlt}
				title='Franchise'
			/>
			<AsideMenuItem
				to='contact-inquiry/contact'
				Icon={faUser}
				title='Contact Inquery'
			/>
			<AsideMenuItem
				to='business-list/business'
				Icon={faUser}
				title='Business List '
			/>

			{/* Invoice start*/}
			<AsideMenuItemWithSub
				to=''
				title='Invoice'
				Icon={faFileInvoice}>
				<AsideMenuItem
					to='invoice/create'
					title='Create Invoice'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='invoice/list'
					title='Invoice List'
					hasBullet={true}
				/>
			</AsideMenuItemWithSub>
			{/* Invoice End*/}

			{/* Subscription start*/}

			<AsideMenuItemWithSub
				to=''
				title='Subscription'
				Icon={faCalendarDays}>
				<AsideMenuItem
					to='plan'
					title='Plan'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='subscription'
					title='Subscription'
					hasBullet={true}
				/>
			</AsideMenuItemWithSub>

			{/* Subscription End*/}

			<AsideMenuItem
				Icon={faChartLine}
				to='expense-details'
				title='Expense'
			/>

			{/* FWG start*/}
			{/* <AsideMenuItemWithSub
						to=''
						title='FWG'
						Icon={faDumbbell}>
						<AsideMenuItem
							to='fitness-plan'
							title='Fitness Plan'
							hasBullet={true}
						/>
						<AsideMenuItem
							to='free-session'
							title='Free Session'
							hasBullet={true}
						/>
						<AsideMenuItem
							to='#'
							title='Team Member'
							hasBullet={true}
						/>
					</AsideMenuItemWithSub> */}
			{/* FWG End*/}

			{/* FGIIT start*/}
			<AsideMenuItemWithSub
				to=''
				title='FGIIT'
				Icon={faBook}>
				<AsideMenuItem
					to='fitness-courses'
					title='Fitness Course'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='scholarship-result'
					title='Scholarship Result'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='books'
					title='Books'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='e-book'
					title='E-Book'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='lectures'
					title='Lectures'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='lecture-comment'
					title='Lecture Comment'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='job-placement'
					title='Job & Placement'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='student-batch'
					title='Students Batch'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='exam'
					title='Exams'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='project-submission'
					title='Project Submission'
					hasBullet={true}
				/>
			</AsideMenuItemWithSub>
			{/* FGIIT End*/}

			{/* FGDIGITAL start*/}
			{/* <AsideMenuItemWithSub
						to=''
						title='FG Digital'
						Icon={faBullhorn}>
						<AsideMenuItem
							to='digital-plan'
							title='Digital Plan'
							hasBullet={true}
						/>
					</AsideMenuItemWithSub> */}
			{/* FGDIGITAL End*/}

			{/* Users start*/}
			<AsideMenuItemWithSub
				to=''
				title='Users'
				Icon={faUsers}>
				<AsideMenuItem
					to='users'
					title='All Users'
					hasBullet={true}
				/>
				{/* <AsideMenuItem
							to='diet-prefrence'
							title='Diet Prefrence'
							hasBullet={true}
						/> */}
			</AsideMenuItemWithSub>
			{/* Users End*/}

			{/* Orders start*/}
			<AsideMenuItemWithSub
				to=''
				title='Orders'
				Icon={faCartArrowDown}>
				<AsideMenuItem
					to='all-order'
					title='All Orders'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='fitness-course-order'
					title='Fitness Course'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='book-order'
					title='Book'
					hasBullet={true}
				/>
				{/* <AsideMenuItem
							to='digital-plan-order'
							title='Digital Plan'
							hasBullet={true}
						/> */}
				{/* <AsideMenuItem
							to='fitness-plan-order'
							title='Fitness Plan'
							hasBullet={true}
						/> */}
			</AsideMenuItemWithSub>
			{/* Orders End*/}

			{/* Cart start*/}
			<AsideMenuItemWithSub
				to=''
				title='Cart'
				Icon={faCartArrowDown}>
				<AsideMenuItem
					to='/fgiit/cart/add-to-cart'
					title='User Cart'
					Icon={faCartShopping}
				/>
				<AsideMenuItem
					to='/fgiit/cart/abandoned-list'
					title='Abandoned Checkout'
					Icon={faJar}
				/>
			</AsideMenuItemWithSub>
			{/* Cart End*/}

			{/* Training & Placement start*/}
			<AsideMenuItemWithSub
				to=''
				title='Training & Placement'
				Icon={faBuildingCircleCheck}>
				<AsideMenuItem
					to='employee-inquiry'
					title='Employee Application'
					hasBullet={true}
				/>
				<AsideMenuItem
					to='employer-inquiry'
					title='Employer Application'
					hasBullet={true}
				/>
			</AsideMenuItemWithSub>
			{/* Training & Placement End*/}
		</>
	)
}
