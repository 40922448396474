/* eslint-disable jsx-a11y/anchor-is-valid */
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import { KTCard } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import LengthMenu from '../../../components/LengthMenu'
import SearchFilter from '../../../components/SearchFilter'
import TableButton from '../../../components/TableButton'
import UsersListPagination from '../../../components/TablePagination'
import TableSort from '../../../components/TableSort'
import { GetDietPlan, RemoveDietPlan } from '../../../Functions/FWG'

const DietsDetails: React.FC = () => {
	const [searchTerm, setSearchTerm] = useState('')
	const [dietData, setDietData] = useState([])
	const [metaData, setMetaData] = useState<any>()
	const [pagination, setPagination] = useState({
		page: 1,
		itemsPerPage: 10,
	})
	const [sort, setSort] = useState('createdAt')
	const [sortOrder, setSortOrder] = useState<QuerySortOptions>('desc')
	const [loading, setLoading] = useState(false)

	const adminType = localStorage.getItem('admin_type')

	const fetchData = async (page?: number) => {
		setLoading(true)
		try {
			const portalInfo = localStorage.getItem('portal_info')
			const fgGroupInfo = localStorage.getItem('fg_group_info')

			let adminInfo: any = null

			let dietPlansData: any

			if (portalInfo) {
				adminInfo = JSON.parse(portalInfo)

				if (searchTerm) {
					dietPlansData = await GetDietPlan({
						page: page || pagination.page,
						limit: pagination.itemsPerPage,
						search: searchTerm,
						sort,
						createdById: adminInfo._id,
						sortOrder,
					})
				} else {
					dietPlansData = await GetDietPlan({
						page: page || pagination.page,
						limit: pagination.itemsPerPage,
						sort,
						createdById: adminInfo._id,
						sortOrder,
					})
				}
			} else if (fgGroupInfo) {
				adminInfo = JSON.parse(fgGroupInfo)
				if (searchTerm) {
					dietPlansData = await GetDietPlan({
						page: page || pagination.page,
						limit: pagination.itemsPerPage,
						search: searchTerm,
						sort,
						sortOrder,
					})
				} else {
					dietPlansData = await GetDietPlan({
						page: page || pagination.page,
						limit: pagination.itemsPerPage,
						sort,
						sortOrder,
					})
				}
			}

			const metaData: any = dietPlansData.metadata
			setMetaData(metaData.pagination)
			setDietData(dietPlansData.data)
		} catch (error: any) {
			console.error(error)
		}
		setLoading(false)
	}
	useEffect(() => {
		fetchData()
	}, [pagination.page, pagination.itemsPerPage, sort, sortOrder])

	useEffect(() => {
		if (searchTerm) {
			setPagination((prev) => ({ ...prev, page: 1 }))
			if (pagination.page === 1) fetchData()
		}
	}, [searchTerm])

	const deleteDietBundle = async (dietID: string) => {
		try {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You are about to Remove This Diet.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes',
			}).then(async (result) => {
				if (result.isConfirmed) {
					const response: any = await RemoveDietPlan({ id: dietID })
					if (response.status === 200) {
						setDietData(dietData.filter((diet: any) => diet._id !== dietID))
						toast.success('Diet Remove successfully')
					} else {
						toast.error('Failed to Remove Diet')
					}
				}
			})
		} catch (error: any) {
			toast.error(error.message)
		}
	}

	const handlePageChange = (page: number) => {
		setPagination({ ...pagination, page })
	}

	const handleItemsPerPageChange = (value: number) => {
		setPagination({ ...pagination, itemsPerPage: value })
	}

	const sortableFields = [
		{ title: 'ID', field: '_id' },
		{ title: 'Diet Plan', field: 'plan_name' },
		{ title: 'Food Category', field: 'category' },
		{ title: 'Diet Type', field: 'food_type' },
		{ title: 'Diet Duration', field: 'days' },
	]

	const handleSortChange = (newSort: string, newSortOrder: QuerySortOptions) => {
		setSort(newSort)
		setSortOrder(newSortOrder)
	}

	const handleCopy = (id: string) => {
		navigator.clipboard
			.writeText(id)
			.then(() => {
				toast.success('ID copied to clipboard!')
			})
			.catch((err) => {
				console.error('Failed to copy ID: ', err)
				toast.success('Failed to copy ID!')
			})
	}

	const handleKeyPress = (event: React.KeyboardEvent<HTMLSpanElement>, id: string) => {
		if (event.key === 'Enter' || event.key === ' ') {
			handleCopy(id)
		}
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>Diets Details</PageTitle>
			<KTCard>
				<div className='card-header border-0 pt-6'>
					<div className='card-title'>
						<SearchFilter
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
						/>
						<LengthMenu
							expenseData={dietData}
							handleItemsPerPageChange={handleItemsPerPageChange}
						/>
					</div>
					<div className='card-toolbar'>
						<TableButton
							action='add'
							to='/fwg/diet-details/diet-create'
							text='Create'
						/>
					</div>
				</div>

				<div className='py-4 card-body'>
					<div className='table-responsive'>
						<table
							id='kt_table_users'
							className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-row-dashed table-row-gray-300 align-middle'>
							<TableSort
								sortableFields={sortableFields}
								sort={sort}
								sortOrder={sortOrder}
								onSortChange={handleSortChange}
							/>
							<tbody className='text-gray-600 fw-bold'>
								{loading ? (
									<tr>
										<td
											colSpan={12}
											className='text-center'>
											<div className='d-flex justify-content-center align-items-center mb-4 my-7'>
												<div
													className='spinner-border text-primary'
													role='status'>
													<span className='visually-hidden'>Loading...</span>
												</div>
											</div>
										</td>
									</tr>
								) : (
									dietData.map((data: any, index: number) => {
										const actualIndex = (pagination.page - 1) * pagination.itemsPerPage + index + 1
										return (
											<tr key={actualIndex}>
												<td>
													<span className='text-dark fw-bold  d-block mb-1 fs-6'>
														{actualIndex}
													</span>
												</td>
												<td
													onClick={() => handleCopy(data._id)}
													onKeyPress={(event) => handleKeyPress(event, data._id)}
													role='button'
													tabIndex={0}>
													<span className='text-dark d-flex fw-bold  d-block mb-1 fs-6'>
														<FontAwesomeIcon
															icon={faCopy}
															className='fs-3 me-2 text-success'
														/>
														{data._id}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold  d-block mb-1 fs-6'>
														{data.plan_name}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold  d-block mb-1 fs-6'>
														{data.diet_food_category.category}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold  d-block mb-1 fs-6'>
														{data.food_type}
													</span>
												</td>
												<td>
													<span className='text-dark fw-bold  d-block mb-1 fs-6'>
														{data.days}
													</span>
												</td>
												<td>
													<div className='d-flex'>
														<TableButton
															action='edit'
															className='d-flex align-items-center'
															to={`/fwg/diet-details/diet-edit?diet_id=${data._id}`}
														/>

														<TableButton
															action='assign'
															text='Assign'
															className='d-flex align-items-center'
															to={'/fwg/diet-details/diet-assign?plan_id=' + data._id}
														/>

														<TableButton
															action='remove'
															onClick={() => deleteDietBundle(data._id)}
														/>
													</div>
												</td>
											</tr>
										)
									})
								)}
							</tbody>
						</table>
					</div>
					{dietData.length === 0 && !loading && (
						<div className='d-flex text-center w-100 align-content-center justify-content-center mt-5'>
							<b>No records found</b>
						</div>
					)}
					{dietData.length > 0 && (
						<UsersListPagination
							totalPages={metaData?.totalPages}
							currentPage={pagination.page}
							onPageChange={handlePageChange}
						/>
					)}
				</div>
			</KTCard>
		</>
	)
}

export default DietsDetails
