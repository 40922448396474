/* eslint-disable react/jsx-no-target-blank */
import {
	faCalendarCheck,
	faChartSimple,
	faCheck,
	faComment,
	faFolder,
	faHome,
	faPersonWalking,
} from '@fortawesome/free-solid-svg-icons'
import { AsideMenuItem } from '../AsideMenuItem'

export function EmployeeAsideMenuItems() {
	return (
		<>
			<AsideMenuItem
				to='/employee/dashboard'
				Icon={faHome}
				title='Dashboard'
			/>
			<AsideMenuItem
				to='/employee/employee-chat'
				Icon={faComment}
				title='Chat'
			/>
			<AsideMenuItem
				to='/employee/task'
				Icon={faCheck}
				title='Task'
			/>
			{/* <AsideMenuItem
						to='/employee/lead'
						Icon={faUserPlus}
						title='Lead'
					/> */}
			<AsideMenuItem
				to='/employee/document'
				Icon={faFolder}
				title='Document'
			/>
			<AsideMenuItem
				to='/employee/sop'
				Icon={faChartSimple}
				title='SOP'
			/>
			<AsideMenuItem
				to='/employee/target'
				Icon={faCalendarCheck}
				title='Target'
			/>
			<AsideMenuItem
				to='/employee/leave-manager'
				Icon={faPersonWalking}
				title='Leave Manager'
			/>
			{/* <AsideMenuItem
						to='/employee/rating-manager'
						Icon={faStar}
						title='Rating Manager'
					/> */}
		</>
	)
}
