/* eslint-disable react/jsx-no-target-blank */
import { faFacebook, faGoogle, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
	faFileInvoiceDollar,
	faFileLines,
	faHome,
	faPeopleGroup,
	faSliders,
	faUserGroup,
	faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { AsideMenuItem } from '../AsideMenuItem'
import { AsideMenuItemWithSub } from '../AsideMenuItemWithSub'

export function MasterAsideMenuItems() {
	return (
		<>
			<AsideMenuItem
				to='/master/dashboard'
				Icon={faHome}
				title='Dashboard'
			/>
			<AsideMenuItemWithSub
				to=''
				title='Facebook'
				Icon={faUsers}>
				<AsideMenuItem
					to='/master/facebook'
					Icon={faFacebook}
					title='Facebook'
				/>
				<AsideMenuItem
					to='/master/configuration'
					Icon={faSliders}
					title='Configuration'
				/>
			</AsideMenuItemWithSub>

			<AsideMenuItem
				to='/master/google-analytics'
				Icon={faGoogle}
				title='Google Analytics'
			/>
			<AsideMenuItem
				to='/master/fg-group'
				Icon={faUserGroup}
				title='FG Group'
			/>
			<AsideMenuItem
				to='/master/fwg'
				Icon={faPeopleGroup}
				title='FWG'
			/>
			<AsideMenuItem
				to='/master/report'
				Icon={faFileLines}
				title='Report'
			/>
			<AsideMenuItemWithSub
				to=''
				title='Personal Invoice'
				Icon={faFileInvoiceDollar}>
				<AsideMenuItem
					to='master-invoice/create'
					title='Create Invoice'
					hasBullet={true}
				/>
				{/* <AsideMenuItem
							to='master-invoice/list'
							title='Invoice List'
							hasBullet={true}
						/> */}
			</AsideMenuItemWithSub>
			<AsideMenuItem
				to='/master/whatsapp-automation'
				Icon={faWhatsapp}
				title='Whatsapp Automation'
			/>
		</>
	)
}
