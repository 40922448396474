import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { KTCard, KTIcon } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import DateFilter from '../../../components/DateRangePicker'
import SearchFilter from '../../../components/SearchFilter'
import Table from '../../../components/Table'
import UsersListPagination from '../../../components/TablePagination'
import { FileUploadToFGGroup } from '../../../Functions/FGGroup'
import { ActionTask, CreateTask, GetTask } from '../../../Functions/FGGroup/TaskManagement'

type TaskFormData = {
	taskName: string
	remark: string
	start_date: Date | any
	end_date: Date | any
	file: File | null
}

const EmployeeTask: React.FC = () => {
	let employeeData: any = localStorage.getItem('fg_group_info')
	const [searchTerm, setSearchTerm] = useState('')
	const [assignTaskData, setAssignTaskData] = useState<any[]>([])
	const [loading, setLoading] = useState(false)
	const [metaData, setMetaData] = useState<any>()
	const [sort, setSort] = useState('createdAt')
	const [sortOrder, setSortOrder] = useState<QuerySortOptions>('desc')
	const [visibleDetails, setVisibleDetails] = useState<string | null>(null)
	const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date] | null>(null)
	const [showModal, setShowModal] = useState(false)
	const [selectedOption, setSelectedOption] = useState('fileUpload')
	const [showActionModal, setShowActionModal] = useState(false)
	const [actionTaskId, setActionTaskId] = useState<string | null>(null)
	const [selectedStatus, setSelectedStatus] = useState('PENDING')
	const [remark, setRemark] = useState('')
	// Recording
	const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
	const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
	const [audioURL, setAudioURL] = useState<string | null>(null)
	const [audioFile, setAudioFile] = useState<File | null>(null)
	const [isRecording, setIsRecording] = useState(false)
	const [isPlaying, setIsPlaying] = useState(false)

	const [pagination, setPagination] = useState({
		page: 1,
		itemsPerPage: 10,
	})
	const [taskFormData, setTaskFormData] = useState<TaskFormData>({
		taskName: '',
		remark: '',
		start_date: null,
		end_date: null,
		file: null,
	})

	const fetchAssignTaskData = async (page?: number) => {
		let employeeData: any = localStorage.getItem('fg_group_info')
		employeeData = JSON.parse(employeeData)
		setLoading(true)
		let startDate: Date | null = null
		let endDate: Date | null = null

		if (selectedDateRange) {
			;[startDate, endDate] = selectedDateRange
			endDate = new Date(endDate.setHours(23, 59, 59, 999))
		}
		try {
			const response = await GetTask({
				admin_id: employeeData._id,
				page: page || pagination.page,
				limit: pagination.itemsPerPage,
				search: searchTerm,
				sort,
				sortOrder,
			})
			const filteredData: any = response.data.filter((task: any) => {
				const taskStartDate = new Date(task.start_date)
				const taskEndDate = new Date(task.end_date)
				if (startDate && endDate) {
					return (
						(taskStartDate >= startDate && taskStartDate <= endDate) ||
						(taskEndDate >= startDate && taskEndDate <= endDate) ||
						(taskStartDate <= startDate && taskEndDate >= endDate)
					)
				}
				return true
			})

			setAssignTaskData(filteredData)

			const metaData: any = response.metadata
			setMetaData(metaData.pagination)
		} catch (error) {
			console.error(error)
		}
		setLoading(false)
	}

	useEffect(() => {
		employeeData = JSON.parse(employeeData)
		fetchAssignTaskData()
	}, [pagination.itemsPerPage, pagination.page, sort, sortOrder, selectedDateRange])

	useEffect(() => {
		if (searchTerm) {
			setPagination((prev) => ({ ...prev, page: 1 }))
			if (pagination.page === 1) fetchAssignTaskData()
		}
	}, [searchTerm])

	const handleSubmit = async (e: React.FormEvent) => {
		try {
			const { taskName, remark, start_date, end_date, file } = taskFormData

			let fileUrl = ''
			if (file) {
				const response: any = await FileUploadToFGGroup([file], {
					directory: 'files',
				})
				fileUrl = response.data?.fileURLs[0]
				toast.success('File uploaded successfully')
			}

			const payload: any = {
				task_title: taskName,
				remark_by_employee: remark,
				start_date: start_date,
				end_date: end_date,
				file_url: fileUrl,
				admin_id: JSON.parse(employeeData)._id,
			}

			await CreateTask(payload)

			setShowModal(false)
			toast.success('Task Created Successfully')
			fetchAssignTaskData()
		} catch (error: any) {
			toast.error(error.message)
			console.error(error)
		}
	}

	const handleInputTask = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, files } = e.target

		if (name === 'file' && files) {
			const selectedFile = files[0]
			setTaskFormData((prevState) => ({
				...prevState,
				file: selectedFile,
			}))
		} else {
			setTaskFormData((prevState) => ({
				...prevState,
				[name]: name === 'endDate' ? new Date(value) : value,
			}))
		}
	}

	const startRecording = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault() // Prevent form submission
		const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
		const recorder = new MediaRecorder(stream)
		setMediaRecorder(recorder)
		recorder.ondataavailable = (event) => {
			const blob = event.data
			setAudioBlob(blob)
			setAudioURL(URL.createObjectURL(blob)) // Update audioURL with the recorded audio

			const file = new File([blob], 'recording.mp3', { type: 'audio/mp3' })
			setAudioFile(file)

			setTaskFormData((prevState) => ({
				...prevState,
				file: file,
			}))
		}
		recorder.start()
		setIsRecording(true)
	}

	const stopRecording = () => {
		mediaRecorder?.stop()
		setIsRecording(false)
	}

	const startAudio = (e: any) => {
		const audio = new Audio(audioURL || '')
		audio.play()
		setIsPlaying(true)
		audio.onended = () => {
			setIsPlaying(false)
		}
	}

	const stopAudio = () => {
		const audio = new Audio(audioURL || '')
		audio.pause()
		setIsPlaying(false)
	}

	const handlePageChange = (page: number) => {
		setPagination({ ...pagination, page })
	}

	const sortableFields = [
		{ title: 'Task', field: 'task_title' },
		{ title: 'Remark By Employee', field: 'remark_by_employee' },
		{ title: 'Remark By HR', field: 'remark_by_hr' },
		{ title: 'File', field: 'file_url' },
		{ title: 'Start Date', field: 'start_date' },
		{ title: 'End Date', field: 'end_date' },
		{ title: 'Status', field: 'status' },
	]

	const handleSortChange = (newSort: string, newSortOrder: QuerySortOptions) => {
		setSort(newSort)
		setSortOrder(newSortOrder)
	}

	const handleRowClick = (id: string) => {
		if (window.innerWidth <= 1024) {
			setVisibleDetails(visibleDetails === id ? null : id)
		}
	}

	const openActionModal = (task_id: string) => {
		setActionTaskId(task_id)
		setShowActionModal(true)
	}

	// Function to handle the action
	const handleActionSubmit = async () => {
		const payload: any = {
			task_id: actionTaskId,
			status: selectedStatus,
			remark_by_employee: remark,
		}
		try {
			await ActionTask(payload)
			toast.success('Task Completed Successfully')
			fetchAssignTaskData()
			setShowActionModal(false)
		} catch (error: any) {
			toast.error(error.message)
		}
	}

	const ShowDocument = (document: any) => {
		const downloadUrl = `https://files.fggroup.in/${document}`
		window.open(downloadUrl)
	}

	const handleDateRangeChange = (range: [Date, Date] | null) => {
		setSelectedDateRange(range)
		setPagination((prev) => ({ ...prev, page: 1 }))
	}

	const filteredTaskData = assignTaskData.filter((data: any) =>
		data?.task_title?.toLowerCase().includes(searchTerm.toLowerCase())
	)
	const paginatedTaskData = filteredTaskData
		.reverse()
		.slice(
			(pagination.page - 1) * pagination.itemsPerPage,
			pagination.page * pagination.itemsPerPage
		)

	const [expandedDescription, setExpandedDescription] = useState<{ [key: number]: boolean }>({})

	const toggleDescription = (index: number) => {
		setExpandedDescription((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}))
	}

	const truncateString = (str: any, num: any) => {
		if (!str || typeof str !== 'string') {
			return ''
		}
		if (str.length <= num) {
			return str
		}
		return str.slice(0, num) + '...'
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>Task Assignment</PageTitle>
			<KTCard>
				<h2 className='m-6 mb-0'>Currently Running Tasks</h2>
				<div className='row card-header border-0 pt-0 align-items-center ms-0 mx-md-6 p-0'>
					<div className='col-md-9'>
						<div className='row mt-3 justify-content-between'>
							<div className='col-md-12 mt-md-7 d-flex justify-content-end align-items-center card-toolbar'>
								<div className='d-md-flex'>
									<DateFilter
										onDateRangeChange={handleDateRangeChange}
										leftValue='-270px'
									/>
									<div className='mt-md-0 mt-4 me-md-3 me-0'>
										<button
											onClick={() => setShowModal(true)}
											className='ms-md-3 mt-md-1 btn btn-primary btn-sm w-md-20 w-100'>
											<KTIcon
												iconName='plus'
												className='fs-2'
											/>
											Add Task
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-md-3 mt-md-7 mt-2 d-flex align-items-center position-relative ps-0'>
						<SearchFilter
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
						/>
					</div>
				</div>

				<div className='py-4 card-body'>
					<div className='table-responsive'>
						<Table
							data={paginatedTaskData}
							columns={sortableFields}
							sort={sort}
							sortOrder={sortOrder}
							disableSortFields={[
								'task_title',
								'remark_by_employee',
								'remark_by_hr',
								'file_url',
								'start_date',
								'end_date',
								'status',
							]}
							onSortChange={handleSortChange}
							renderRow={(task: any, index: number, actualIndex: number, isVisible: boolean) => (
								<React.Fragment key={task._id}>
									<tr
										onClick={() => handleRowClick(task._id)}
										className='data-row'>
										<td className='text-center'>
											<div className='d-flex'>
												<FontAwesomeIcon
													icon={faPlusCircle}
													className='mx-2 ms-5 mb-1 plus-icon'
													style={{ color: '#607D8B', fontSize: '18px' }}
												/>
												<span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
													{actualIndex}
												</span>
											</div>
										</td>
										<td>
											<span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
												{task.task_title}
											</span>
										</td>
										<td>
											<span
												className='text-dark fw-bold text-hover-primary d-md-block d-none mb-1 fs-6'
												onClick={() => toggleDescription(index)}
												title={task.remark_by_employee}
												style={{ cursor: 'pointer' }}>
												{task.remark_by_employee
													? expandedDescription[index]
														? task.remark_by_employee
														: truncateString(task.remark_by_employee, 40)
													: '-'}
											</span>
											<span
												className='text-dark fw-bold text-hover-primary d-md-none d-block mb-1 fs-6'
												style={{ cursor: 'pointer' }}
												title={task.remark_by_employee}
												onClick={() => toggleDescription(index)}>
												{task.remark_by_employee
													? expandedDescription[index]
														? task.remark_by_employee
														: truncateString(task.remark_by_employee, 20)
													: '-'}
											</span>
										</td>
										<td>
											<span
												className='text-dark fw-bold text-hover-primary d-md-block d-none mb-1 fs-6'
												onClick={() => toggleDescription(index)}
												title={task.remark_by_hr}
												style={{ cursor: 'pointer' }}>
												{task.remark_by_hr
													? expandedDescription[index]
														? task.remark_by_hr
														: truncateString(task.remark_by_hr, 40)
													: '-'}
											</span>
											<span
												className='text-dark fw-bold text-hover-primary d-md-none d-block mb-1 fs-6'
												style={{ cursor: 'pointer' }}
												title={task.remark_by_hr}
												onClick={() => toggleDescription(index)}>
												{task.remark_by_hr
													? expandedDescription[index]
														? task.remark_by_hr
														: truncateString(task.remark_by_hr, 20)
													: '-'}
											</span>
										</td>
										<td>
											{task.file_url ? (
												task.file_url.endsWith('.mp3') ? (
													<audio
														controls
														src={`https://files.fggroup.in/${task.file_url}`}
														style={{ width: '150px' }}
													/>
												) : (
													<img
														onClick={() => ShowDocument(task.file_url)}
														src={`https://files.fggroup.in/${task.file_url}`}
														alt='task file'
														style={{ width: '150px', height: 'auto' }}
														role='button'
													/>
												)
											) : (
												'No file'
											)}
										</td>
										<td>
											<span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
												{dayjs(task.start_date).format('DD/MM/YYYY hh:mm:ss A')}
											</span>
										</td>
										<td>
											<span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
												{dayjs(task.end_date).format('DD/MM/YYYY hh:mm:ss A')}
											</span>
										</td>
										<td>
											<span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
												{task.status}
											</span>
										</td>
										<td>
											{task.status == 'COMPLETED' ? (
												<b>Already Complete</b>
											) : (
												<button
													className='btn btn-success btn-sm'
													onClick={() => openActionModal(task._id)}>
													Complete
												</button>
											)}
										</td>
									</tr>
									{isVisible && (
										<tr className={`detail-row ${isVisible ? 'is-visible' : ''}`}>
											<td colSpan={12}>
												<div>
													<strong>{sortableFields[0].title}:</strong> {task.task_title}
													<br />
													<strong>{sortableFields[1].title}:</strong> {task.remark_by_employee}
													<br />
													<strong>{sortableFields[2].title}:</strong> {task.remark_by_hr}
													<br />
													<strong>{sortableFields[3].title}:</strong>{' '}
													{task.file_url ? (
														task.file_url.endsWith('.mp3') ? (
															<audio
																controls
																src={`https://files.fggroup.in/${task.file_url}`}
															/>
														) : (
															<img
																onClick={() => ShowDocument(task.file_url)}
																src={`https://files.fggroup.in/${task.file_url}`}
																alt='task file'
																style={{ width: '150px', height: 'auto' }}
																role='button'
															/>
														)
													) : (
														'No file'
													)}
													<br />
													<strong>{sortableFields[4].title}:</strong>{' '}
													{dayjs(task.start_date).format('DD/MM/YYYY hh:mm:ss A')}
													<br />
													<strong>{sortableFields[5].title}:</strong>{' '}
													{dayjs(task.end_date).format('DD/MM/YYYY hh:mm:ss A')}
													<br />
													<strong>{sortableFields[6].title}:</strong> {task.status}
												</div>
											</td>
										</tr>
									)}
								</React.Fragment>
							)}
							visibleDetails={visibleDetails}
							pagination={pagination}
							setPagination={setPagination}
							loading={loading}
						/>
					</div>
					{paginatedTaskData.length === 0 && !loading && (
						<div className='d-flex text-center w-100 align-content-center justify-content-center mt-5'>
							<b>No records found</b>
						</div>
					)}

					{paginatedTaskData.length > 0 && (
						<UsersListPagination
							totalPages={Math.ceil(assignTaskData.length / pagination.itemsPerPage)}
							currentPage={pagination.page}
							onPageChange={handlePageChange}
						/>
					)}
				</div>
			</KTCard>

			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				centered>
				<Modal.Header closeButton>
					<Modal.Title>Add Task</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* Form fields */}
					<Form>
						<Form.Group
							className='mb-3'
							controlId='taskTitle'>
							<Form.Label>Task Title</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter task title'
								name='taskName'
								value={taskFormData.taskName}
								onChange={handleInputTask}
								required
							/>
						</Form.Group>

						<Form.Group
							className='mb-3'
							controlId='remark'>
							<Form.Label>Remark</Form.Label>
							<Form.Control
								type='text'
								placeholder='Type your remark here (optional)'
								name='remark'
								value={taskFormData.remark}
								onChange={handleInputTask}
								required
							/>
						</Form.Group>

						<Form.Group
							className='mb-3'
							controlId='start_date'>
							<Form.Label>Start Date</Form.Label>
							<Form.Control
								type='date'
								name='start_date'
								value={taskFormData.start_date ? taskFormData.start_date : ''}
								onChange={handleInputTask}
								required
							/>
						</Form.Group>

						<Form.Group
							className='mb-3'
							controlId='end_date'>
							<Form.Label>End Date</Form.Label>
							<Form.Control
								type='date'
								name='end_date'
								value={taskFormData.end_date ? taskFormData.end_date : ''}
								onChange={handleInputTask}
								required
							/>
						</Form.Group>

						<Form.Group controlId='uploadOption'>
							<Form.Label className=' mt-5'>Select File / Audio</Form.Label>
							<div className='d-flex mb-4'>
								<Form.Check
									type='radio'
									label='File Upload'
									name='uploadOption'
									id='fileUpload'
									checked={selectedOption === 'fileUpload'}
									onChange={() => setSelectedOption('fileUpload')}
									className='me-4'
								/>
								<Form.Check
									type='radio'
									label='Send Audio'
									name='uploadOption'
									id='sendAudio'
									checked={selectedOption === 'sendAudio'}
									onChange={() => setSelectedOption('sendAudio')}
								/>
							</div>
						</Form.Group>

						{selectedOption === 'fileUpload' && (
							<Form.Group
								className='mb-3'
								controlId='file'>
								<Form.Label>File Upload</Form.Label>
								<Form.Control
									type='file'
									name='file'
									onChange={handleInputTask}
									required
								/>
							</Form.Group>
						)}

						{selectedOption === 'sendAudio' && (
							<Form.Group
								className='mb-3'
								controlId='audio'>
								<Form.Label>Send Audio</Form.Label>
								<br />
								<Button
									variant='success'
									size='sm'
									onClick={startRecording}
									disabled={isRecording}>
									Record Audio
								</Button>
								<Button
									variant='info'
									size='sm'
									className='mx-3'
									onClick={startAudio}
									disabled={!audioURL || isPlaying}>
									Start Audio
								</Button>
								<Button
									variant='danger'
									size='sm'
									onClick={isRecording ? stopRecording : stopAudio}
									disabled={!isRecording && !isPlaying}>
									{isRecording ? 'Stop Recording' : 'Stop Audio'}
								</Button>
								{audioURL && (
									<audio
										controls
										src={audioURL}
										className='mt-5'>
										<track
											kind='captions'
											srcLang='en'
											label='English captions'
											default
										/>
										Your browser does not support the audio element.
									</audio>
								)}
							</Form.Group>
						)}
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => setShowModal(false)}>
						Close
					</Button>
					<Button
						variant='primary'
						onClick={handleSubmit}>
						Add Task
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showActionModal}
				centered
				onHide={() => setShowActionModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Complete Task</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId='status'>
						<Form.Label>Status:</Form.Label>
						<div>
							<Form.Check
								type='radio'
								label='PENDING'
								name='taskStatus1'
								id='taskStatus1'
								value='PENDING'
								checked={selectedStatus === 'PENDING'}
								onChange={(e) => setSelectedStatus(e.target.value)}
								className='mb-3'
							/>
							<Form.Check
								type='radio'
								label='COMPLETED'
								name='taskStatus2'
								id='taskStatus2'
								value='COMPLETED'
								checked={selectedStatus === 'COMPLETED'}
								onChange={(e) => setSelectedStatus(e.target.value)}
								className='mb-5'
							/>
						</div>
					</Form.Group>
					<Form.Group controlId='remark'>
						<Form.Label>Remark:</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter Remark'
							value={remark}
							onChange={(e) => setRemark(e.target.value)}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => setShowActionModal(false)}>
						Cancel
					</Button>
					<Button
						variant='primary'
						onClick={handleActionSubmit}>
						Confirm
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default EmployeeTask
